@import url("./styles/fonts.css");
@import url("./styles/reset.css");

body {
  margin: 0;
  font-family: "Averta", "Helvetica Neue", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.errorMessage {
  color: red;
  font-size: 0.75em;
  display: relative;
}

.message-error {
  color: red;
  font-size: 0.75em;
  display: relative;
  margin-bottom: 5px;
  margin-top: 5px;
  height: 1rem;
  text-align: center;
}

.input {
  width: 100%;
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #dfeaec;
  border-radius: 2px;
}

.input:focus {
  outline: none;
  box-shadow: 0px 0px 12px 0.8px #3fd6e8;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


