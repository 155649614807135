.form-botton {
  display: flex;
  width: 100%;
  justify-content: center;
}

.form-botton,
.item-footer {
  a {
    text-decoration: none;
    text-align: center;
  }
  .back {
    margin-right: 10px;
    width: 30%;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    background-color: #d9fffe;
  }
}

.restore {
  width: 40%;
  line-height: 2;
  margin-bottom: 10px;
  border: 0;
  color: white;
  border-radius: 16px;
  height: 32px;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  background-color: #f76b1c;
  outline: none;
}

p {
  color: #003057;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.container-restore {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}
