.btn-save-xl {
  width: 200px;
  margin-left: 20px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 8px 12px;
  border: 0;
  background-color: #F76B1C;
  border-radius: 16px;
  height: 35px; }

.header {
  > span {
    text-align: center; } }
