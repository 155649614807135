
@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-thinitalic-webfont.eot' );
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-thinitalic-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-thinitalic-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-thinitalic-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-thinitalic-webfont.svg#avertathin_italic') format('svg');
    font-weight: 100;
    font-style: italic;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-thin-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-thin-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-thin-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-thin-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-thin-webfont.svg#avertathin') format('svg');
    font-weight: 100;
    font-style: normal;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathinitalic-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathinitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathinitalic-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathinitalic-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathinitalic-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathinitalic-webfont.svg#avertaextrathin_italic') format('svg');
    font-weight: 200;
    font-style: italic;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathin-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathin-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathin-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathin-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrathin-webfont.svg#avertaextrathin') format('svg');
    font-weight: 200;
    font-style: normal;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-lightitalic-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-lightitalic-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-lightitalic-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-lightitalic-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-lightitalic-webfont.svg#avertalight_italic') format('svg');
    font-weight: 300;
    font-style: italic;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-light-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-light-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-light-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-light-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-light-webfont.svg#avertalight') format('svg');
    font-weight: 300;
    font-style: normal;


}


@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-regularitalic-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-regularitalic-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-regularitalic-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-regularitalic-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-regularitalic-webfont.svg#avertaregular_italic') format('svg');
    font-weight: 400;
    font-style: italic;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-regular-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-regular-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-regular-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-regular-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-regular-webfont.svg#averta_regularregular') format('svg');
    font-weight: 400;
    font-style: normal;

}






@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibolditalic-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibolditalic-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibolditalic-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibolditalic-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibolditalic-webfont.svg#avertasemibold_italic') format('svg');
    font-weight: 600;
    font-style: italic;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibold-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibold-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibold-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibold-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-semibold-webfont.svg#avertasemibold') format('svg');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-bolditalic-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-bolditalic-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-bolditalic-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-bolditalic-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-bolditalic-webfont.svg#avertabold_italic') format('svg');
    font-weight: 700;
    font-style: italic;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-bold-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-bold-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-bold-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-bold-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-bold-webfont.svg#avertabold') format('svg');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabolditalic-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabolditalic-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabolditalic-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabolditalic-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabolditalic-webfont.svg#avertaextrabold_italic') format('svg');
    font-weight: 800;
    font-style: italic;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabold-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabold-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabold-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabold-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-extrabold-webfont.svg#avertaextrabold') format('svg');
    font-weight: 800;
    font-style: normal;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-blackitalic-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-blackitalic-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-blackitalic-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-blackitalic-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-blackitalic-webfont.svg#avertablack_italic') format('svg');
    font-weight: 900;
    font-style: italic;

}




@font-face {
    font-family: 'Averta';
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-black-webfont.eot');
    src: url('../asset/Averta\ WebFont\ Family\ TDF/averta-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-black-webfont.woff2') format('woff2'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-black-webfont.woff') format('woff'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-black-webfont.ttf') format('truetype'),
         url('../asset/Averta\ WebFont\ Family\ TDF/averta-black-webfont.svg#avertablack') format('svg');
    font-weight: 900;
    font-style: normal;

}