.restore-error {
  width: 100%;
  text-decoration: none;
  text-align: center;
  line-height: 2;
  margin-bottom: 10px;
  border: 0;
  color: white;
  border-radius: 16px;
  height: 32px;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  background-color: #f76b1c;
  outline: none;
}
