.form-client {
  display: grid;
  grid-row-gap: 1rem;
  grid-template-rows: repeat(3, 1fr);
}

.item-footer {
  display: flex;
  justify-content: center;
  align-self: flex-end;
}

.none {
  display: none;
}


