.login-container {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(
    44.45deg,
    #69f3dd 0%,
    #3fd6e8 39.76%,
    #25b2da 66.44%,
    #0584c9 100%
  );
}

.button:disabled {
  opacity: 0.5;
}

.Login {
  width: 35em;
  height: 10em;
  min-height: 30em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  position: relative;
  z-index: 99;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.2);
    border-radius: 20px;
    position: relative;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding: 17px 10px;
    //transition: transform 200ms ease-in-out;
  }
}

.container {
  box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.2);
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    display: flex;
    align-self: center;
    span {
      color: #003057;
      font-size: 32px;
      font-weight: 800;
      line-height: 40px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    .image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2em;
      img {
        width: 30%;
      }
    }

    .form {
      margin-top: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: fit-content;
        label {
          color: #003057;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          font-size: 20px;
        }

        input {
          margin-top: 5px;
          min-width: 18em;
          height: 37px;
          padding: 0px 10px;
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          background-color: #f3f3f3;
          border: 0;
          border-radius: 4px;
          transition: all 250ms ease-in-out;

          &:focus {
            outline: none;
            box-shadow: 0px 0px 12px 0.8px #3fd6e8;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    a {
      color: #024164;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      text-decoration: none;
    }
    .btn {
      line-height: 2;
      margin-bottom: 10px;
      border: 0;
      background-color: #57dbe8;
      border-radius: 16px;
      height: 32px;
      transition: all 250ms ease-in-out;
      cursor: pointer;
      &:hover {
        background-image: linear-gradient(20deg, #3fd6e8, #0584c9);
      }
      &:focus {
        outline: none;
      }
    }
  }
  .btn {
    width: 50%;
    line-height: 2;
    margin-bottom: 10px;
    border: 0;
    background-color: #57dbe8;
    border-radius: 16px;
    height: 32px;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    &:hover {
      background-image: linear-gradient(20deg, #3fd6e8, #0584c9);
    }
    &:focus {
      outline: none;
    }
  }
}


.last-item{
  margin-bottom: 0px;
}




